export const HolidaysSale15 = () => {
  return (
    <div
      style={{
        border: "1px solid #800c32",
        borderRadius: "18px",
        padding: "2px 7px",
      }}
    >
      <p
        style={{
          margin: "0",
          textAlign: "center",
          fontWeight: "700",
          color: "#800c32",
          fontSize: "12px",
        }}
      >
        -15% HOLIDAY SALE
      </p>
    </div>
  );
};
