import { formatCurrency } from "src/app/utils/formatCurrency";
import styles from "./emp.module.css";
import { Tooltip } from "../../../tooltip";
import { useCardCtx } from "../../card-context";
import { HolidaysSale15 } from "./holidays-sale15";

export const Emp = () => {
  const cardCtx = useCardCtx();

  const old_price = cardCtx.auction.old_price;
  const currentPrice = cardCtx.auction.buy_now_price;

  return (
    <>
      {!old_price ? (
        <div className={styles.root}>
          <Tooltip content="Estimated Market Price (EMP) is calculated by Crurated using a range of different sources, taking into account the condition and rarity of the bottles.">
            <span className={styles.label}>Estimated Market Price</span>
          </Tooltip>
          <span className={styles.value}>{formatCurrency(cardCtx.emp)}</span>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            margin: "6px 0",
          }}
        >
          <span
            style={{
              textDecoration: "line-through",
              fontSize: "12px",
              color: "#000",
            }}
          >
            {old_price} €
          </span>
          <span style={{ fontSize: "16px", color: "#000", fontWeight: "700" }}>
            {currentPrice} €
          </span>
          <HolidaysSale15 />
        </div>
      )}
    </>
  );
};
