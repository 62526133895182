import { CSSProperties } from "react";
import { BannerCarousel } from "./banner-carousel";
import { BannerSlide } from "./banner-slide";
import inviteFriendDesktop from "src/images/referral/invite-friend-desktop.jpeg";
import inviteFriendMobile from "src/images/referral/invite-friend-mobile.jpeg";
import christmasDesktop from "src/images/christmas/christmas-desktop.jpeg";
import christmasMobile from "src/images/christmas/christman-mobile.jpeg";
import { A } from "../a";

type Props = {
  style?: CSSProperties;
};

export function Banner({ style }: Props) {
  return (
    <BannerCarousel
      style={style}
      slides={[
        <BannerSlide
          key="banner-slide-2"
          srcDesktop={christmasDesktop}
          srcMobile={christmasMobile}
          alt="Christman-banner"
          markup={<A to="/store/shop" />}
        />,
        <BannerSlide
          key="banner-slide-1"
          srcDesktop={inviteFriendDesktop}
          srcMobile={inviteFriendMobile}
          alt="Reitano Campaign"
          markup={
            <A to="https://page.crurated.com/referafriend-2024-termsandconditions" />
          }
        />,
      ]}
    />
  );
}
