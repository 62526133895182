import { useQuery } from "@tanstack/react-query";
import { QKEY } from "./QKEY";
import { upfetchV1 } from "./upfetch";
import { apiSystemConfigSchemaV1 } from "../models/SystemConfig";

const token = localStorage.getItem("accessToken");

export const fetchInfoConfig = () => {
  return upfetchV1({
    url: "config/GetInfoConfig/",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
    schema: apiSystemConfigSchemaV1,
  });
};

export const useSystemConfigQuery = () => {
  return useQuery({
    queryKey: [QKEY.CONFIG],
    queryFn: fetchInfoConfig,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
