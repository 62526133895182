import styles from "./form.module.css";
import { CSSProperties, ReactNode } from "react";
import { ID_AUCTION_TYPE } from "src/app/const";
import { Auction } from "src/app/models/auctions";
import { BidLiner } from "./bid-liner";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { cn } from "src/app/utils/cn";
import { Tooltip } from "src/app/components/tooltip";
import { ButtonShare } from "src/app/components/auction-card/atoms/button-share";
import { Countdown } from "src/app/components/countdown";
import ButtonAuctionRefresh from "./button-refresh";
import { HolidaysSale15 } from "../../../md/atoms/holidays-sale15";

type Props = {
  auction: Auction;
  actionLeft: ReactNode;
  actionCenter: Exclude<ReactNode, null | false>;
  actionRight: ReactNode;
  empLabel: string;
  empTooltipContent: string;
  inputLeft: ReactNode;
  inputRight: ReactNode;
  price: number;
  quantity: number;
  totalLabel: string;
  totalTooltipContent: string;
  creditCardLabel?: ReactNode;
  bidInfos?: ReactNode;
  infoMessage?: ReactNode;
  style?: CSSProperties;
};

export const Form = ({
  auction,
  actionLeft,
  actionCenter,
  actionRight,
  empLabel,
  empTooltipContent,
  inputLeft,
  inputRight,
  price,
  quantity,
  totalLabel,
  totalTooltipContent,
  creditCardLabel,
  bidInfos,
  infoMessage,
  style,
}: Props) => {
  const isMobile = useIsMobile();

  const showHolidaySale = [
    ID_AUCTION_TYPE.PRIVATE_SALE,
    ID_AUCTION_TYPE.PERMANENT,
    ID_AUCTION_TYPE.EN_PRIMEUR,
  ].includes(auction.type.id);

  const old_price = auction.old_price;

  const isInHolidaySale = !!old_price && !!showHolidaySale;

  const showCountdown = ![
    ID_AUCTION_TYPE.PRIVATE_SALE,
    ID_AUCTION_TYPE.PERMANENT,
    ID_AUCTION_TYPE.EN_PRIMEUR,
  ].includes(auction.type.id);

  const showBidLiner = [ID_AUCTION_TYPE.COLLECTION].includes(auction.type.id);

  return (
    <form
      className={cn(styles.root, isMobile ? styles.mobile : styles.desktop)}
      onSubmit={(e) => e.preventDefault()}
      style={style}
    >
      <div className={styles.head}>
        {/* TODO: A fine campagna natalizia rimettere div vuoto */}
        {showCountdown ? <Countdown targetDate={auction.finish_data} /> : <></>}
        {!!isInHolidaySale && <HolidaysSale15 />}
        <div className={styles.toprightButtons}>
          <ButtonShare />
          <ButtonAuctionRefresh />
        </div>
      </div>

      {bidInfos}

      <div className={styles.bottomGroup}>
        {!!showBidLiner && (
          <BidLiner
            style={{ marginTop: "auto" }}
            bidPercentage={auction.bid_percentage}
          />
        )}

        <div className={styles.controls}>
          <div className={styles.inputs}>
            {inputLeft}
            {inputRight}
          </div>

          <div
            style={{
              marginTop: isInHolidaySale ? "8px" : "14px",
            }}
            className={styles.total}
          >
            {!!isInHolidaySale && (
              <span
                style={{
                  textDecoration: "line-through",
                  fontSize: "16px",
                  marginRight: "4px",
                }}
              >
                {auction.old_price} €
              </span>
            )}
            <span className={styles.totalValue}>
              {formatCurrency(quantity * price)}
            </span>
            {isInHolidaySale ? (
              <div>
                <Tooltip content={totalTooltipContent}>
                  <p style={{ margin: "0" }} className={styles.totalLabel}>
                    {totalLabel}
                  </p>
                </Tooltip>
              </div>
            ) : (
              <Tooltip content={totalTooltipContent}>
                <span className={styles.totalLabel}>{totalLabel}</span>
              </Tooltip>
            )}
          </div>

          <hr className={styles.divider} />
          <div className={styles.emp}>
            <Tooltip content={empTooltipContent}>
              <span>{empLabel}</span>
            </Tooltip>
            <span>
              {formatCurrency(
                quantity * auction.crurated_estimated_market_price,
              )}
            </span>
          </div>
        </div>

        <div className={styles.actions}>
          {actionLeft}
          {actionCenter}
          {actionRight}
        </div>
        {infoMessage}

        <div className={styles.crediCard}>{creditCardLabel}</div>
      </div>
    </form>
  );
};
