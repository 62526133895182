import { ButtonWish } from "./components/button-wish";
import { Select } from "./components/select";
import { useIsAuctionFinished } from "src/app/hooks/useIsAuctionFinished";
import { Form } from "./components/form";
import { ButtonSubmit } from "./components/button-submit";
import { ButtonArchive } from "./components/button-archive";
import { isAuctionOfBarrel, isAuctionOfLot } from "src/app/models/auctions";
import { useCardCtx } from "../../card-context";
import { CreditCardLabel } from "./components/credit-card-label";
import { ID_AUCTION_TYPE } from "src/app/const";
import { Button } from "src/app/components/button";
import { PiWarningDuotone } from "react-icons/pi";
import { useMedia } from "react-use";
import InfoMessage from "./components/info-message";

export const ForStore = () => {
  const isWide = useMedia("(min-width: 1044px)");
  const cardCtx = useCardCtx();
  const isFinished = useIsAuctionFinished(cardCtx.auction);
  const isEnPrimeur = cardCtx.auction.type.id === ID_AUCTION_TYPE.EN_PRIMEUR;

  return (
    <Form
      auction={cardCtx.auction}
      empLabel="Estimated Market Price"
      empTooltipContent="Estimated Market Price (EMP) is calculated by Crurated using a range of different sources, taking into account the condition and rarity of the bottles."
      totalLabel="Excl. of processing fees and taxes"
      totalTooltipContent="Excludes any processing fees, applicable taxes and/or duties and delivery charges. Please check our T&C or Contact the Customer Service for further details."
      price={cardCtx.price}
      quantity={cardCtx.quantity}
      inputLeft={
        <Select
          label="Quantity"
          value={cardCtx.quantity}
          options={cardCtx.auction.quantityOptions}
          onChange={cardCtx.setQuantity}
          disabled={isFinished || !cardCtx.auction.canBid}
        />
      }
      inputRight={
        <Select
          label={
            isAuctionOfBarrel(cardCtx.auction)
              ? `Offer per ${cardCtx.auction.barrel!.nft_liters} L`
              : cardCtx.auction.hasExperience
                ? "Offer per Pass"
                : isEnPrimeur
                  ? "Price per bottle"
                  : "Offer per Lot"
          }
          value={cardCtx.price}
          options={cardCtx.auction.priceOptions}
          disabled
        />
      }
      actionLeft={
        <ButtonArchive
          isArchived={cardCtx.isArchived}
          isLoading={cardCtx.isArchiveLoading}
          onClick={cardCtx.toggleArchive}
        />
      }
      actionCenter={
        isAuctionOfLot(cardCtx.auction) &&
        !!isEnPrimeur &&
        cardCtx.auction.canAddToWaitingList ? (
          <Button
            variant="outlined"
            label={`${cardCtx.auction.addedToWaitingList ? "Remove from" : "Add to"} waiting list`}
            isLoading={cardCtx.isEnprimeurWaitingListLoading}
            disabled={cardCtx.isEnprimeurWaitingListLoading}
            style={{ flexGrow: 1 }}
            onClick={() =>
              isAuctionOfLot(cardCtx.auction) &&
              cardCtx.auction.addedToWaitingList
                ? cardCtx.handleRemoveEnprimeurToWaitingList()
                : cardCtx.handleAddEnprimeurToWaitingList()
            }
          />
        ) : (
          <ButtonSubmit
            label="Buy now"
            forbidden={!cardCtx.auction.canBid || isFinished}
            isLoading={cardCtx.isMakeABidLoading}
            onClick={cardCtx.handleBuyNow}
          />
        )
      }
      actionRight={
        <ButtonWish
          isWished={cardCtx.isWished}
          onClick={cardCtx.toggleIsWished}
          isLoading={cardCtx.isWishLoading}
        />
      }
      infoMessage={
        !!cardCtx.auction.whyCantBid && (
          <InfoMessage
            isWide={isWide}
            title={cardCtx.auction.whyCantBid}
            icon={
              <PiWarningDuotone
                style={{ display: "block", fontSize: 16 }}
                color="#C87A06"
              />
            }
          />
        )
      }
      creditCardLabel={<CreditCardLabel />}
    />
  );
};
